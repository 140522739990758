<template>
  <div
    class="c-nav-bar"
    @click="onClick"
  >
    <div>
      <slot name="notice"></slot>
    </div>
    <div 
      class="c-nav-bar__content"
    >
      <slot>
        <NavBarItem
          v-for="(link, index) in links"
          :key="index"
          :index="index"
        >
          {{ link }}
        </NavBarItem>
      </slot>
    </div>
  </div>
</template>

<script>
import NavBarItem from './NavBarItem.vue'
export default {
  components: {
    NavBarItem
  },
  props: {
    // 导航项的链接
    links: {
      type: Array,
      default: () => []
    },
    // 当前激活的导航项
    active: {
      type: Number,
      default: 0
    },
    // 是否可以点击导航项
    canClickInxList: {
      type: Array,
      default: () => []
    },
    isBuyNow: {
      type: Boolean,
      default: false
    },
  },
  provide () {
    return {
      active: this.active,
      canClickInxList: this.canClickInxList
    }
  },
  methods: {
    onClick (e) {
      const target = e.target
      if (target.classList.contains('c-nav-bar-item__label')) {
        const index = target.dataset.index
        this.$emit('onClick', index)
      }
    },
  },
  emits: ['onClick']
}
</script>

<style lang="less" scoped>
.c-nav-bar {
  &__content {
    text-align: center;
    line-height: normal;
    margin: 20px auto 18px;
  }
}
</style>
