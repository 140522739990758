<template>
  <div
    :class="[
      'payment-item',
      'payment-cod',
      'j-cod-payment',
      {
        'payment-selected':
          status.cardPay == item.code && mall_caculate_info.isSupportCod,
        fold: isShowViewMore && inx >= checkout.results.folded_position,
      },
    ]"
    type="cod"
    data-test="cod1"
    tabindex="0"
    role="radio"
    :aria-checked="
      status.cardPay == item.code && mall_caculate_info.isSupportCod
    "
    @click="clickPayment(item)"
  >
    <s-radio
      :model-value="
        !!(
          status.cardPay == item.code && mall_caculate_info.isSupportCod
        )
      "
      :label="true"
      :toggle="true"
      :disabled="
        item.enabled && mall_caculate_info.isSupportCod ? false : true
      "
      size="radio20"
      theme="icon"
      gap="0"
    />
    <img
      v-if="item.enabled && mall_caculate_info.isSupportCod"
      class="payment-src"
      :src="transformImg({ img: item.logo_url })"
    />
    <img
      v-else
      class="payment-src"
      :src="transformImg({ img: item.gray_logo_url })"
    />

    <div
      class="payment-item__effiency-right"
      :class="`j-item-right-${item.id}`"
    >
      <span
        v-show="item.show_title && item.title"
        :class="[
          'payment-des',
          item.enabled == 1 ? '' : 'payment-disabled',
        ]"
      >
        {{ item.title }}
      </span>

      <!-- <span
        v-if="
          !paymentDescriptionAbt ||
            (paymentDescriptionAbt && status.cardPay != item.code)
        "
      >
        <span
          v-if="!!paymentDesTips"
          :aria-label="paymentDesTips"
        >
        </span>
        <s-popover
          v-if="!!paymentDesTips"
          class="payment-item-tip"
          placemen="bottom"
          trigger="hover"
          :content="paymentDesTips"
        >
          <template #reference>
            <Icon
              name="sui_icon_doubt_14px_1"
              color="#666666"
              size="15px"
            />
          </template>
        </s-popover>
      </span> -->

      <span class="payment-item-tip">
        <Icon
          name="sui_icon_doubt_16px_1"
          color="#666666"
          size="14px"
          @click.stop="showCodTipDialog = true"
        />
      </span>
      <!-- fix 置灰文案展示 -->
      <div
        v-if="status.cardPay != item.code && item.enabled != 1"
        class="cod_help_dec cod-txt-gray"
      >
        {{ paymentDesTips }}
      </div>
      <template v-if="lang == 'ar'">
        <span
          v-show="status.cardPay == item.code"
          :class="[
            'cod_help_fee',
            { pament_opacity: !mall_caculate_info.isSupportCod },
          ]"
        >
          {{ language.SHEIN_KEY_PC_16729 }}
          <template
            v-if="
              status.cardPay == item.code &&
                mall_caculate_info.local_cod_price &&
                mall_caculate_info.local_cod_price.amount > 0
            "
          >
            {{ mall_caculate_info.local_cod_price.amountWithSymbol }}
          </template>
        </span>

        <div
          :class="[
            'cod_help_dec',
            { pament_opacity: !mall_caculate_info.isSupportCod },
          ]"
        >
          <template
            v-if="
              mall_caculate_info.cod_price_limit &&
                mall_caculate_info.cod_price_limit.min_local &&
                mall_caculate_info.cod_price_limit.max_local
            "
          >
            {{
              language.SHEIN_KEY_PC_16728.replace(
                '[min]',
                mall_caculate_info.cod_price_limit.min_local
                  .amountWithSymbol
              ).replace(
                '[max]',
                mall_caculate_info.cod_price_limit.max_local
                  .amountWithSymbol
              )
            }}
          </template>
          <template v-else>
            {{
              language.SHEIN_KEY_PC_16728.replace('[min]', '').replace(
                '[max]',
                ''
              )
            }}
          </template>
        </div>
      </template>
      <!-- 在线支付优惠为“折扣”类型PaymentItemCod -->
      <p
        v-if="isShowDiscountTips"
        class="discount-percent_tips dicount-flex-sy"
      >
        <!-- 在线优惠 -->
        <template 
          v-if="isShowOnlinePayDiscount && getOnlinePayDiscountValue.discountType != 2" 
        >
          <span
            v-if="
              getOnlinePayDiscountValue.min &&
                +getOnlinePayDiscountValue.min.amount != 0
            "
            class="discount-tips-item"
          >
            <!-- 满减优惠 -->
            <template
              v-if="
                mall_caculate_info.onlinePayDiscountInfo
                  .onlinePayDiscountValue[item.code].discountType == 0
              "
            >
              {{
                template(
                  mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].min.amountWithSymbol,
                  mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].amount
                    .amountWithSymbol,
                  language.SHEIN_KEY_PC_16461
                )
              }}
            </template>

            <!-- 折扣优惠 -->
            <template
              v-else-if="
                mall_caculate_info.onlinePayDiscountInfo
                  .onlinePayDiscountValue[item.code].discountType == 1
              "
            >
              <span>
                {{
                  template(
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].min.amountWithSymbol,
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].percent + '%OFF',
                    language.SHEIN_KEY_PC_16461
                  )
                }}.
              </span>
              <span
                v-if="
                  mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].limit_value &&
                    +mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].limit_value.amount != 0
                "
              >
                {{
                  language.SHEIN_KEY_PC_20465.replace(
                    '{limitValue}',
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].limit_value
                      .amountWithSymbol
                  )
                }}
              </span>
            </template>
          </span>
          <span v-else>
            <span
              v-if="
                getOnlinePayDiscountValue &&
                  getOnlinePayDiscountValue.discountType == 1 &&
                  getOnlinePayDiscountValue.limit_value &&
                  +getOnlinePayDiscountValue.limit_value.amount != 0
              "
            >
              {{
                language.SHEIN_KEY_PC_20465.replace(
                  '{limitValue}',
                  mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].limit_value
                    .amountWithSymbol
                )
              }}
            </span>
          </span>
        </template>

        <span
          v-if="getOnlinePayDiscountValue.discountType == 2 && randomDiscountAbt && item.enabled"
          class="discount-tips-item"
        >
          <span>
            {{ randomDiscountDes(item) }}
          </span>
        </span>
        <span v-if="isShowOnlinePayDiscount && isShowCardBinDiscount"> + </span>
        <span
          v-if="isShowCardBinDiscount"
          class="discount-tips-item card-bin-discount-tips"
        >
          <span>{{ cardBinDiscountDesc }}</span>
          <Icon
            name="sui_icon_doubt_16px_1"
            color="#959595"
            size="16px"
            @click.stop="handleCardBinDiscountTips"
          />
        </span>
      </p>
      <!-- 问号提示 -->
      <slide-up-down
        v-if="paymentDescriptionAbt"
        :active="status.cardPay == item.code"
        :duration="500"
        :responsive="true"
      >
        <div class="paytm-upi-input hint">
          <p
            v-show="
              !mall_caculate_info.notSupportCodPaymentMethodReasonTip &&
                item.show_description &&
                ((item.enabled && item.description) ||
                  (!item.enabled && item.gray_description))
            "
            class="content"
          >
            {{ item.enabled ? item.description : item.gray_description }}
          </p>
          <p
            v-show="mall_caculate_info.notSupportCodPaymentMethodReasonTip"
            class="content"
            onclick="showPreRobot()"
          >
            {{ language.SHEIN_KEY_PC_14778 }}
          </p>
        </div>
      </slide-up-down>
    </div>

    <s-dialog 
      v-model:visible="showCodTipDialog" 
      :type="`W720`" 
      :show-close="true"
      append-to-body
      @opened="handleDialogOpen"
      @closed="handleDialogClose"
    >
      <img
        class="cod-tip-dialog__logo"
        :src="transformImg({ img: item.logo_url })"
      />
      <div class="cod-tip-dialog__title">
        {{ language.SHEIN_KEY_PC_31333 }}
      </div>
      <div class="cod-tip-dialog__tips">
        <div 
          v-for="(tip, index) in codTips" 
          :key="tip.title" 
          class="cod-tip-dialog__tip"
        >
          <div class="cod-tip-dialog__order">
            {{ index + 1 }}
          </div>
          <div class="cod-tip-dialog__desc">
            <p class="cod-tip-dialog__desc-title">
              {{ tip.title }}
            </p>
            <p 
              v-for="desc in tip.desc" 
              :key="desc"
              class="cod-tip-dialog__desc-content"
            >
              {{ desc }}
            </p>
          </div>
        </div>
      </div>
      <div class="cod-tip-dialog__btn">
        <s-button
          :type="['H44PX', 'primary']"
          @click="showCodTipDialog = false"
        >
          {{ language.SHEIN_KEY_PC_25915 }}
        </s-button>
      </div>
    </s-dialog>
  </div>
</template>

<script>
import {
  transformImg,
  template as _commonTemplate,
} from '@shein/common-function'

import slideUpDown from '@sheinfe/vue-slide-up-down'
import { Icon } from '@shein-aidc/icon-vue3'

// const { lang } = gbCommonInfo

export default {
  name: 'PaymentItemCod',
  components: {
    slideUpDown,
    Icon,
  },
  props: {
    status: {
      type: Object,
      default: function () {
        return {}
      },
    },
    item: {
      type: Object,
      default: () => {}
    },
    mall_caculate_info: {
      type: Object,
      default: () => {}
    },
    isShowViewMore: {
      type: Boolean,
      default: false
    },
    inx: {
      type: Number,
      default: 0
    },
    checkout: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paymentDescriptionAbt: {
      type: Boolean,
      default: false,
    },
    paymentDesTips: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isShowDiscountTips: {
      type: [Boolean, Number],
      default: false
    },
    isShowOnlinePayDiscount: {
      type: [Boolean, Number],
      default: false
    },
    getOnlinePayDiscountValue: {
      type: Object,
      default: () => {}
    },
    isShowCardBinDiscount: {
      type: [Boolean, Number],
      default: false
    },
    cardBinDiscountDesc: {
      type: String,
      default: ''
    },
    randomDiscountDes: {
      type: Function
    },
    randomDiscountAbt: {
      type: Boolean,
      default: false
    },
    locals: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      resizeHandler: null,
      showCodTipDialog: false
    }
  },
  computed: {
    lang () {
      return this.locals?.lang ?? gbCommonInfo?.lang
    },
    codTips() {
      return [
        { title: this.language.SHEIN_KEY_PC_31334, desc: [this.language.SHEIN_KEY_PC_31335] },
        { title: this.language.SHEIN_KEY_PC_31336, desc: [this.language.SHEIN_KEY_PC_31337, this.language.SHEIN_KEY_PC_31338] },
        { title: this.language.SHEIN_KEY_PC_31339, desc: [this.language.SHEIN_KEY_PC_31340] }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateVerticalAlign()
    })

    this.resizeHandler = () => {
      this.updateVerticalAlign()
    }
 
    window.addEventListener('resize', this.resizeHandler)
  },
  updated () {
    this.$nextTick(() => {
      this.updateVerticalAlign()
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    transformImg,
    template: _commonTemplate,
    clickPayment(item) {
      this.$emit('clickPayment', item.id, item.code, item)
    },
    handleCardBinDiscountTips() {
      this.$emit('handleCardBinDiscountTips')
    },
    updateVerticalAlign() {
      const element = document.querySelector(`.j-item-right-${this.item.id}`)
      if (element) {
        const itemRightHeight = element.offsetHeight
        const alignStyle = itemRightHeight >= 40 ? 'top' : 'middle'
        element.style.verticalAlign = alignStyle
      }
    },
    handleDialogOpen() {
      this.$emit('codTipExpose')
    },
    handleDialogClose() {
      this.$emit('clickCodTips')
    }
  },
  emits: ['clickPayment', 'handleCardBinDiscountTips', 'clickCodTips', 'codTipExpose']
}
</script>
<style lang="less">
  .payment-item{
		position: relative;
		cursor: pointer;
		font-size: 0;
		border: 1px solid transparent;
		label{
			vertical-align: middle;
		}
		.payment-extra-more {
			display: inline-block;
			vertical-align: middle;
			.extra-tip {
				font-size: 12px;
				display: block;
				color: #666;
				padding-top: 6px;
				a {
					display: inline-block;
					text-decoration: none;
					vertical-align: middle;
					color: #999;
				}
			}
		}
		.discount-percent_tips {
			font-size: 12px;
			line-height: 14px;
		}

		.payment-src{
			width: 60px;
			vertical-align: middle;
		}
		.payment-des{
			font-size: 14px;
			vertical-align: middle;
		}
		.payment-disabled {
			opacity: 0.3;
		}

		.payment-item-tip{
      padding-left: 5px;
			font-size: 12px;
			vertical-align: middle;
		}
	}
  .cod-tip-dialog__logo {
    width: 45px;
    height: 30px;
    border-radius: 4px;
    margin: 0 auto;
    display: block;
  }
  .cod-tip-dialog__title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 4px;
    margin-bottom: 32px;
    line-height: 23px;
    color: @sui_color_gray_dark1;
    text-align: center;
  }
  .cod-tip-dialog__tip {
    display: flex;
    align-items: center;
    position: relative;
    font-family: SF UI Text;

    &:not(:first-child) {
      padding-top: 6px;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 1px;
        height: 50%;
        background: #000;
        left: 11px;
        bottom: 50%;
        z-index: -1;
      }
    }

    &:not(:last-child) {
      padding-bottom: 6px;

      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 1px;
        height: 50%;
        background: #000;
        left: 11px;
        top: 50%;
        z-index: -1;
      }
    }
  }
  .cod-tip-dialog__order {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #000;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-right: 12px;
  }
  .cod-tip-dialog__desc-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: @sui_color_gray_dark1;
    margin-bottom: 4px;
  }
  .cod-tip-dialog__desc-content {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: @sui_color_gray_dark3;
  }
  .cod-tip-dialog__btn {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
  .cod-txt-gray {
    color: #999;
    font-size: 11px;
    font-weight: 400;
  }
</style>
