import { computed } from 'vue'
import { template } from '@shein/common-function'

const useTokenBinDiscount = (cardBinDiscountInfo, cardBin, language) => {
  const allCardBinDiscountMap = computed(() => {
    const { all_card_bin_discount_map = {} } = cardBinDiscountInfo || {}
    return all_card_bin_discount_map
  })

  const type = computed(() => {
    const { type = 0 } =
      allCardBinDiscountMap.value?.[cardBin] || {}

    return type
  })

  const isTokenBinRandomType = computed(() => {
    return type.value === 3
  })

  const getTokenRandomBinDiscountDesc = () => {
    const {
      max_random_amount = {},
      min_random_amount = {},
      min_amount = {},
      issuer_bank_name = ''
    } = allCardBinDiscountMap.value?.[cardBin] || {}

    if (min_amount?.amount > 0) {
      if (issuer_bank_name?.length > 0) {
        return template(
          min_random_amount?.amountWithSymbol,
          max_random_amount?.amountWithSymbol,
          min_amount?.amountWithSymbol,
          issuer_bank_name,
          language.SHEIN_KEY_PC_28783 || ''
        )
      }

      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        min_amount?.amountWithSymbol,
        language.SHEIN_KEY_PC_26181 || ''
      )
    }

    if (issuer_bank_name?.length > 0) {
      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        issuer_bank_name,
        language.SHEIN_KEY_PC_28784 || ''
      )
    }

    return template(
      min_random_amount?.amountWithSymbol,
      max_random_amount?.amountWithSymbol,
      language.SHEIN_KEY_PC_26118 || ''
    )
  }

  const getCardBinDiscountDesc = () => {
    const { total_discount_amount, type = 0 } =
      allCardBinDiscountMap.value?.[cardBin] || {}

    // 优惠类型 1：满减 2：折扣 3 随机立减
    if ([1, 2].includes(type) && total_discount_amount?.amount > 0) {
      return template(
        total_discount_amount?.amountWithSymbol,
        language.SHEIN_KEY_PC_25787 || ''
      )
    }

    if (type === 3) {
      return getTokenRandomBinDiscountDesc()
    }
  }

  return {
    getCardBinDiscountDesc,
    type,
    isTokenBinRandomType
  }
}

export { useTokenBinDiscount }
