<template>
  <div 
    :class="['support-info-module not-fsp-element', {
      'support-info-module__card': securityLogoList.length > 0
    }]"
  >
    <div class="module-item-wrap">
      <!-- We Accept -->
      <div
        v-if="scene === 'Cart' && !!logoList.length"
        class="module-item cursor-hover"
        @click="handleToAccept"
      >
        <div class="support-info-title">
          <sui_icon_paymentsecurity_16px_1
            class="svg-mr-4"
            size="16px"
          />
          <span>
            {{ language.SHEIN_KEY_PC_24818 }}
          </span>
          <sui_icon_more_right_12px
            class="svg-mr-4"
            :is-rotate="GB_cssRight"
          />
        </div>
        <div class="payment-logo-list">
          <img 
            v-for="(item, i) in logoList"
            :key="`${item.id}__${i}`" 
            :src="baseCutImg(item.url)"
            :alt="item.url"
            class="logo-item"
          />
        </div>
      </div>
      <!-- Payment Security -->
      <div
        v-if="scene === 'Checkout'"
        class="module-item"
      >
        <div class="support-info-title">
          <sui_icon_paymentsecurity_16px_1
            class="svg-mr-4"
            size="16px"
          />
          <span>
            {{ paymentTitleText }}
          </span>
        </div>
        <div class="support-info-desc">
          <template v-if="supportAbtInfo.PaymentSecurity == 'Type_A'">
            <p
              v-for="(item, index) in descInfo.payment"
              :key="index"
              class="new-support-info-desc"
            >
              <sui_icon_selected_16px
                size="16px"
                color="#198055"
                class="selectIcon"
              />
              {{ item }}
            </p>
          </template>
          <template v-else>
            {{ template( isRw || IS_RW ? 'ROMWE' : 'SHEIN', language.SHEIN_KEY_PC_24821) }}
          </template>
        </div>
        <div class="payment-logo-list">
          <img 
            v-for="(item, i) in logoList"
            :key="`${item.id}__${i}`" 
            :src="baseCutImg(item.url)"
            :alt="item.url"
            class="logo-item"
          />
        </div>
      </div>
      <!-- Security & Privacy -->
      <div
        class="module-item"
      >
        <div class="support-info-title">
          <sui_icon_privacy_16px_1
            class="svg-mr-4"
            size="16px"
          />
          <span>{{ language.SHEIN_KEY_PC_24822 }}</span>
        </div>
        <div 
          class="support-info-desc"
        >
          <template v-if="supportAbtInfo.PaymentSecurity == 'Type_A'">
            <p
              v-for="(item, index) in descInfo.privacy"
              :key="index"
              class="new-support-info-desc"
            >
              <sui_icon_selected_16px
                size="16px"
                color="#198055"
                class="selectIcon"
              />
              {{ item }}
            </p>
            <div
              class="learn-more"
              @click="initCombinePolicyTip"
            >
              {{ language.SHEIN_KEY_PC_30511 }}
              <sui_icon_more_right_14px_1
                size="14px"
                color="#767676"
              />
            </div>
          </template>
          <template v-else>
            {{ Security_Privacy_Text }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, onBeforeMount, computed } from 'vue'
import { template } from '@shein/common-function'
import { fetchPayDomainLogoApi } from 'public/src/services/api/pay/checkout.js'

import { sui_icon_paymentsecurity_16px_1, sui_icon_more_right_12px, sui_icon_selected_16px, sui_icon_privacy_16px_1, sui_icon_more_right_14px_1 } from '@shein-aidc/icon-vue3'


export default {
  components: {
    sui_icon_paymentsecurity_16px_1,
    sui_icon_more_right_12px,
    sui_icon_selected_16px,
    sui_icon_privacy_16px_1,
    sui_icon_more_right_14px_1,
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    scene: {
      type: String,
      default: ''
    },
    securityLogoList: {
      type: Array,
      default: () => []
    },
    isRw: {
      type: Boolean,
      default: false
    },
    supportAbtInfo: {
      type: Object,
      default: () => ({})
    },
    locals: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    paymentTitleText() {
      return this.supportAbtInfo.PaymentSecurity == 'Type_A' ? this.language?.SHEIN_KEY_PC_30503 : this.language?.SHEIN_KEY_PC_24820
    },
    descInfo(){
      const { SHEIN_KEY_PC_30504, SHEIN_KEY_PC_30505, SHEIN_KEY_PC_30506, SHEIN_KEY_PC_30507, SHEIN_KEY_PC_30508, SHEIN_KEY_PC_30509 } = this.language
      return {
        payment: [SHEIN_KEY_PC_30504, SHEIN_KEY_PC_30505, SHEIN_KEY_PC_30506],
        privacy: [SHEIN_KEY_PC_30507, SHEIN_KEY_PC_30508, SHEIN_KEY_PC_30509]
      }
    }
  },
  setup (props) {

    const IS_RW = computed(() => {
      return props.locals?.IS_RW ?? gbCommonInfo?.IS_RW
    })
    const langPath = computed(() => {
      return props.locals?.langPath ?? gbCommonInfo?.langPath
    })
    const GB_cssRight = computed(() => {
      return props.locals?.GB_cssRight ?? gbCommonInfo?.GB_cssRight
    })

    function handleToAccept () {
      if(typeof window == 'undefined') return
      window.open(IS_RW.value ? `${langPath.value}/How-to-Pay-a-518.html` : `${langPath.value}/How-to-Pay-a-278.html`, '_blank')
    }

    function initCombinePolicyTip () {
      if(typeof window == 'undefined') return
      window?.SHEIN_GLOBAL_PRIVACY && window.SHEIN_GLOBAL_PRIVACY.show({ type: 'privacy' })
    }

    function initSupportInfo () {
      const logoList = ref([])
      if (props?.securityLogoList.length > 0 && ['Cart', 'Checkout'].includes(props?.scene)) {
        logoList.value = props?.securityLogoList
      }
      onBeforeMount(async () => {
        if (props?.securityLogoList.length > 0) {
          logoList.value = props?.securityLogoList
        } else {
          const pageType = props?.scene === 'Cart' ? 1 : 4
          const logoInfo = await fetchPayDomainLogoApi({ 
            page_type: pageType 
          })
          logoList.value = logoInfo?.info?.logoList || []
        }
      })

      return {
        logoList
      }
    }

    const Security_Privacy_Text = computed(() => {
      return props.securityLogoList.length > 0 && props.scene !== 'Cart'
        ? props.language.SHEIN_KEY_PC_24733 
        : template(props.isRw || IS_RW ? 'ROMWE' : 'SHEIN', props.language.SHEIN_KEY_PC_24823)
    })

    return {
      IS_RW,
      GB_cssRight,
      template,
      handleToAccept,
      ...toRefs(initSupportInfo()),
      Security_Privacy_Text,
      initCombinePolicyTip
    }

  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  methods: {
    baseCutImg(url) {
      // 没有cutImg方法或者使用原图时，不裁剪  
      if (!this?.cutImg) {
        return url.replace(/^https?\:/, '').replace(/\b(\.jpg|\.png)\b/, '.webp')
      }
      return this.cutImg(url, 0, {})
    },
  }
}
</script>

<style lang="less">
.support-info-module {
  margin-top: 10px;
  padding: 16px;
  overflow: hidden;
  background-color: @sui_color_white;
  .module-item-wrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .module-item {
      width: 100%;
      margin-bottom: 4px;
    }
    .cursor-hover {
      .support-info-title:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    .support-info-title {
      display: flex;
      align-items: stretch;
      color: @sui_color_safety;
      font-size: 14px;
      line-height: 1.21428;
      font-size: 14px;
      font-family: "SF Pro";
      font-style: normal;
      font-weight: 700;
      .icon-right {
        color: @sui_color_gray_dark3;
      }
      .svg-mr-4 {
        margin-right: 4px;
      }
    }
    .support-info-desc {
      padding-top: 8px;
      font-size: 12px;
      line-height: 1.1666666;
      color: @sui_color_gray_dark3;
      font-family: "SF Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 4px;
      .new-support-info-desc{
        display: flex;
        .selectIcon{
          margin-right: 3px;
        }
      }
      .learn-more{
        margin: 8px 0 0 19px;
        cursor: pointer;
      }
    }
  }
  .payment-logo-list {
    .flexbox();
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 6px;
    .logo-item {
      width: auto;
      height: 32px;
    }
  }

  &__card {
    .module-item-wrap {
      .module-item {
        padding: unset;
      }
    }
  }
}
</style>
