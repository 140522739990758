<template>
  <div 
    v-if="termInfo"
    class="signup-term-info"
    v-html="termInfo"
  ></div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  paymentCode: {
    type: String,
    default: ''
  },
  signupAccountInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const termInfo = computed(() => {
  const signupInfo = props.signupAccountInfo?.[props.paymentCode] || {}
  
  if (props.paymentCode === 'klarna-klarna' && signupInfo?.id) {
    return props.language?.SHEIN_KEY_PC_32757
  }

  return ''
})
</script>

<style lang="less">
.signup-term-info {
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.5;

  a {
    color: @sui_color_link;

    &:hover,&.active,&.focus {
      color: @sui_color_link;
    }
  }
}
</style>
