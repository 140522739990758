import { template } from '@shein/common-function'

export const useCouponsDiscount = language => {
  const percentFormat = num => {
    const newNum = (num * 100) / 100
    const percentNum = newNum + '%'
    return percentNum
  }

  // satisfied_range 当前用户满足的最高档次：0：所有档次都不满足 1：满足第一档 2：满足第二档，3：满足第了档，4：满足第4档，以此类推
  const getCurrentCouponRule = supportBinData => {
    // 多档券
    if (
      supportBinData?.other_coupon_rule?.length > 1 &&
      supportBinData?.satisfied_range > 0
    ) {
      return supportBinData?.other_coupon_rule[
        supportBinData?.satisfied_range - 1
      ]
    }
    return supportBinData?.other_coupon_rule?.[0]
  }

  const getDiscount = (couponTypeId, currentCouponRule) => {
    if (couponTypeId == '1') {
      // 减元
      return template(
        currentCouponRule?.price?.priceSymbol || '',
        language.SHEIN_KEY_PC_15957
      )
    }

    if ([2, 5].includes(+couponTypeId)) {
      // 折扣
      return template(
        percentFormat(currentCouponRule?.price?.price || ''),
        language.SHEIN_KEY_PC_15957
      )
    }
    return ''
  }

  return {
    getDiscount,
    getCurrentCouponRule
  }
}
