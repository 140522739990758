
/**
 * ppga vaulting 选中态
 */
export const PPGA_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * afterpay vaulting 选中态
 */
export const AFTER_PAY_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * dlocal-nupay vaulting 选中态
 */
export const SIGNUP_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

// 不经过 SSR constants 配置
// 从 apollo 中获取的地址错误编辑弹窗 [402003, 402004, 402005, 402007]
export const ADDRESS_ERROR_CODE_EDIT_POPUP = typeof window !== 'undefined' ? window.gbCommonInfo?.ADDRESS_ERROR_CODE_EDIT_POPUP || [] : []

// 下单地址检测 /v2/User/Address/checkAddress 错误码
export const ADDRESS_ERROR_CODE = [401996, ...ADDRESS_ERROR_CODE_EDIT_POPUP]
