
import { computed } from 'vue'

export default (checkoutBFFAbtInfo = {}) => {
  // 是否展示辅助决策信息
  const isShowSupportInfo = computed(() => {
    return checkoutBFFAbtInfo?.SupportInfo?.param?.SupportInfoShow === 'Show'
  })
  return {
    isShowSupportInfo
  }
}
