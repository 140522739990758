<template>
  <span class="c-nav-bar-item">
    <span
      class="c-nav-bar-item__label"
      :class="{
        'c-nav-bar-item__label_active': isActive,
        'c-nav-bar-item__label_actived': isActived,
        'c-nav-bar-item__label_can-click': canClickInxList.includes(index)
      }"
      :data-index="index"
    >
      <slot></slot>
    </span>
    <span class="c-nav-bar-item__separator">
      >
    </span>
  </span>
</template>

<script>
export default {
  inject: ['active', 'canClickInxList'],
  props: {
    // 当前导航项的下标
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    // 是否激活
    isActive () {
      return this.active === this.index
    },
    // 是否已经激活过了
    isActived () {
      return this.active > this.index
    }
  },
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
.c-nav-bar-item {
  color: @sui_color_gray_light1;
  font-size: 16px;
  line-height: 18px;
  &:last-child {
    .c-nav-bar-item__separator {
      display: none;
    }
  }
  &__label {
    &_active {
      color: @sui_color_gray_dark1;
    }
    &_actived {
      color: #666;
    }
    &_can-click {
      cursor: pointer;
    }
  }
  &__separator {
    margin: 0 6px;
  }
}
</style>
