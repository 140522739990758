<template>
  <div
    v-if="commonLocals.isRiskPage"
    class="page-header__header-risk"
  >
    <sui_icon_shein_logo_22px
      width="106px"
      height="22px"
      @click="onLogoClick"
    />
    <span>{{ language.SHEIN_KEY_PC_17794 }}</span>
  </div>
  <div
    v-else
    class="c-page-header j-c-header2"
  >
    <header
      class="container-fluid-1200 c-page-header__content"
      :class="!commonLocals.IS_RW && container1350 && 'container-1350'"
    >
      <div
        class="c-page-header__logo"
        @click="onLogoClick"
      >
        <template v-if="commonLocals.WEB_CLIENT === 'shein'">
          <img
            v-if="isShowFestivalLogo"
            :src="`${commonLocals.PUBLIC_CDN}/she_dist/images/shein_logo_valentine-a7a4d38696.gif`"
            alt="logo"
            class="festival-logo"
          />
          <img
            v-else-if="commonLocals.lang === 'ar'"
            :src="`${commonLocals.PUBLIC_CDN}/she_dist/images/ar/sheinar-5bb0dfb02f.png`"
            alt="logo"
            class="ar-logo"
          />
          <sui_icon_shein_logo_22px
            v-else
            width="106px"
            height="22px"
          />
        </template>
        <template v-else>
          <img
            :src="`${commonLocals.PUBLIC_CDN}/she_dist/rw_images/romwe_logo-7787248200.png`"
            alt="logo"
            class="romwe-logo"
          />
        </template>
      </div>
      <slot></slot>
      <div class="c-page-header__right">
        <div class="c-page-header__secure">
          <img
            :src="`${commonLocals.PUBLIC_CDN}/she_dist/images/sui_img_pay_securecheckout-5ae3c290bb.png`"
            alt=""
            class="icon"
          />
          <span class="text">{{ language.SHEIN_KEY_PC_14785 }}</span>
        </div>
        <div
          class="c-page-header__shopping j-continue-shopping"
          @click="onShoppingClick"
        >
          <span class="text">{{ language.SHEIN_KEY_PC_14542 }}</span>
          <sui_icon_more_right_12px
            size="12px"
            :is-rotate="commonLocals.GB_cssRight"
          />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { sui_icon_more_right_12px, sui_icon_shein_logo_22px } from '@shein-aidc/icon-vue3'
import { daEventCenter } from 'public/src/services/eventCenter'
const { forceHttp } = gbCommonInfo

export default {
  components: {
    sui_icon_more_right_12px,
    sui_icon_shein_logo_22px
  },
  props: {
    customsClickEnv: {
      type: Boolean,
      default: false
    },
    container1350: {
      type: Boolean,
      default: false
    },
    locals: {
      type: Object,
      default: () => (null)
    },
    pageHeaderLanguage: {
      type: Object,
      default: () => (null)
    }
  },
  data() {
    return {
      // IS_RW,
      // language,
      // isRiskPage,
      // WEB_CLIENT,
      // lang,
      // PUBLIC_CDN
    }
  },
  computed: {
    // 是否展示节日logo
    isShowFestivalLogo() {
      return [].includes(this.commonLocals.lang)
    },
    language() {
      return this.pageHeaderLanguage || gbCommonInfo.language
    },
    commonLocals(){
      const { IS_RW, isRiskPage, WEB_CLIENT, lang, PUBLIC_CDN, GB_cssRight } =  this.locals || gbCommonInfo
      return { IS_RW, isRiskPage, WEB_CLIENT, lang, PUBLIC_CDN, GB_cssRight }
    }
  },
  methods: {
    onLogoClick() {
      if(this.customsClickEnv) {
        this.$emit('click-logo')
        return
      }
      window.location.href = forceHttp
    },
    onShoppingClick() {
      if(this.customsClickEnv) {
        this.$emit('click-shopping')
        return
      }
      if(window.SaPageInfo.page_name == 'page_cart'){
        daEventCenter.triggerNotice({
          daId: '1-7-1-5'
        })
      }
      window.location.href = `${forceHttp}/daily-new.html`
    }
  },
  emits: ['click-logo', 'click-shopping']
}
</script>

<style lang="less" scoped>
.page-header__header-risk {
  width: 100%;
	height: 70px;
	background: #fff;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
	line-height: 70px;
	display: inline-block;
  padding-left: 80px;
	span {
		font-size: 22px;
		text-transform: uppercase;
		display: inline-block;
		color: #000;
    margin-left: 15px;
	}
}
.c-page-header {
  height: 48px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1220px;
    height: 100%;
    // container-fluid-1200 的伪元素会导致 justify-content: space-between;有问题
    &::after,
    &::before {
      content: none;
    }

    &.container-1350 {
      min-width: 800px;
      max-width: 1370px;
    }

    @media (min-width: 1366px) {
      &.container-1350 {
        padding-left: 45px;
        padding-right: 45px;
        min-width: 800px;
        max-width: 1440px;
      }
    }
  } 
  &__logo {
    cursor: pointer;
    margin-right: 16px;
    .festival-logo {
      width: 160px;
      height: 40px;
    }
    .ar-logo {
      width: 64px;
      height: 32px;
    }
    .romwe-logo {
      width: 120px;
      height: 24px;
    }
  }
  &__right {
    display: flex;
  }
  &__secure {
    display: flex;
    align-items: center;
    margin: 0 24px;
    .icon {
      width: 24px;
      height: 24px;
    }
    .text {
      margin-left: 4px;
      color: @sui_color_safety;
      font-size: 14px;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  &__shopping {
    display: flex;
    align-items: center;
    color: #767676;
    cursor: pointer;
    &:hover {
      .text {
        text-decoration: underline;
      }
    }
    .text {
      margin-right: 2px;
      font-size: 14px;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}
</style>
