import { getCurrentInstance } from 'vue'

export default function useVm() {
  const vm = getCurrentInstance()
  if (!vm || !vm.proxy) throw new Error('must be called in setup')
  return {
    vm: vm.proxy,
    store: vm.proxy.$store
  }
}
