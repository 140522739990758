<template>
  <div class="bin-coupons-block">
    <div
      v-if="isSupportBin"
      v-expose="{
        id: '1-11-1-212',
        data: binCouponType
      }"
      class="pay-bin-coupons"
    >
      <span class="txt txt-discount">
        {{ discountText }}
      </span>
      <span
        v-if="normalPayData.showCard"
        class="txt txt-small txt-normal-pay"
      >
        {{ normalPayData.cardName }}
      </span>
    </div>
    <span
      v-if="isSupportBin && isPayBinCouponsAdd"
      class="add-tip"
    >
      +
    </span>
  </div>
</template>

<script setup>
/**
 * @file 支付方式组件: 卡bin优惠券入口组件
 */
import { computed } from 'vue'
import { template } from '@shein/common-function'

const props = defineProps({
  scene: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => {}
  },
  cardBin: {
    type: String,
    default: ''
  },
  isPayBinCouponsAdd: {
    type: [Boolean, Number],
    default: false
  },
  payCode: {
    type: String,
    default: ''
  },
  couponList: {
    type: Array,
    default: () => []
  },
  orderInfo: {
    type: Object,
    default: () => {}
  }
})

const orderInfoCouponList = computed(() => {
  return props.orderInfo?.coupon_list || []
})

const supportBinData = computed(() => {
  const nowUseCoupon = props.couponList?.find(item => {
    return (
      item?.card?.card_name && // 卡 bin 优惠券
      (item?.paymentLimit?.length === 0 ||
        item?.paymentLimit?.includes(props.payCode)) // 有支付方式限制
    )
  })

  // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
  return nowUseCoupon
})

const isSupportBin = computed(() => {
  return !!supportBinData.value
})

const discount = computed(() => {
  return (
    orderInfoCouponList.value.find(
      item => item.coupon === supportBinData.value?.couponCode
    )?.coupon_price?.amountWithSymbol || ''
  )
})

const discountText = computed(() => {
  return `${template(discount.value, props.language.SHEIN_KEY_PC_31098)}`
})

// 在非token支付的情况下，是否展示card 和  cardName取值
const normalPayData = computed(() => {
  let data = {
    showCard: false,
    cardName: ''
  }

  if (supportBinData.value) {
    data.showCard = true
    data.cardName = supportBinData.value?.card?.card_name
  }
  return data
})

const binCouponType = computed(() => {
  return {
    bincoupon_type: 1,
    payment_method: props.payCode
  }
})
</script>

<style scoped lang="less">
.bin-coupons-block {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  .pay-bin-coupons {
    display: flex;
    align-items: center;
    margin-bottom: unset;
    border: 1px #edc2aa solid;
    border-radius: 1px;
    background-color: #fff6f3;
    padding: 1px 6px;
    position: relative;
    color: #c44a01;
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 16px;
    max-width: 100%;

    // 超出一行展示省略号
    .txt {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .txt-discount {
      margin-right: 4px;
    }
    .txt-small {
      font-weight: 400;
    }
    .txt-normal-pay {
      position: relative;
      padding-left: 4px;
      margin-right: 4px;
      &::before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 15%;
        left: 0;
        width: 1/75rem;
        height: 70%;
        border-left: 1/75rem solid var(---sui_color_discount_dark, #c44a01);
      }
    }
  }

  .add-tip {
    margin: 0 4px;
  }
}

.coupons-before-after() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  width: 4px;
  height: 8px;
  border: 1px solid #edc2aa;
  transform: translateY(-50%);
  background-color: white;
}

.pay-bin-coupons::before {
  .coupons-before-after();
  left: -1px;
  border-left-color: #fff;
  border-radius: 0 4px 4px 0;
}

.pay-bin-coupons::after {
  .coupons-before-after();
  right: -1px;
  border-right-color: #fff;
  border-radius: 4px 0 0 4px;
}
</style>
