<template>
  <div class="card-img-wrap">
    <img
      v-if="
        item.cardMetadata &&
          item.cardMetadata.content &&
          item.cardMetadata.content.imageUrl
      "
      class="card-img-bg"
      :src="item.cardMetadata.content.imageUrl"
    />
    <template v-else>
      <img
        class="card-img-bg"
        :src="defaultCardBg"
      />
      <img
        v-show="item.webLogo"
        :src="item.webLogo"
        alt=""
        class="card-img-logo"
      />
    </template>
    <span class="card-number">{{ item.cardNo }}</span>

    <div
      v-if="'isGray' in item"
      class="img-gray"
    ></div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// const { PUBLIC_CDN } = gbCommonInfo

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  locals: {
    type: Object,
    default: () => ({
      PUBLIC_CDN: ''
    })
  },
  wrapSize: {
    type: String,
    default: '40px'
  }
})

const defaultCardBg = computed(() => {
  if (typeof window === 'undefined') {
    return `${props.locals?.PUBLIC_CDN}/she_dist/images/user/payment-default-card-83cb0dac07.png`
  }
  return `${gbCommonInfo?.PUBLIC_CDN}/she_dist/images/user/payment-default-card-83cb0dac07.png`
})
</script>

<style lang="less" scoped>
.card-img-wrap {
    width: v-bind('wrapSize');
    position: relative;
    display: inline-block;
    img.card-img-bg {
        width: 100%;
        border-radius: 1.70px;
    }
    .card-img-logo {
      position: absolute;
      right: 2px;
      top: 2px;
      width: 14px;
      z-index: @zindex-out + 1;
    }
    .card-number {
      position: absolute;
      z-index: @zindex-out + 1;
      text-align: left;
      bottom: 6px;
      left: 6px;
      font-size: 10px;
      color: @sui_color_white;
      line-height: 1.2 * 0.66666;
      transform: scale(0.6666);
      -webkit-transform: scale(0.6666);
      transform-origin: left bottom;
      -webkit-transform-origin: left bottom;
    }
  
      .img-gray {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 40px;
        height: 100%;
        background: linear-gradient(251deg, #ababab 0%, #8f8f8f 100%);
        border-radius: 1.70px;
        opacity: 0.9;
      }
  }
</style>
